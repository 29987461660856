import consumer from "./consumer"
require("datatables.net")

consumer.subscriptions.create("ActivityChannel", {
  connected() {
    this.perform("appear")
  },

  disconnected() {

  },

  received(data) {
    if(data.model == "Applicant" || data.model == "AdminUser" || data.model == "CrewChief") {
      let elements = document.getElementsByClassName(`${data.model.toLowerCase()}-${data.user_id}-status`),
          table = document.getElementById("onlineUsers"),
          model = data.model == "CrewChief" ? "MetroEmployee" : data.model;
      let amodel;

      switch(data.model) {
        case "CrewChief": 
          amodel = "crew_chiefs";
          break;
        case "AdminUser": 
          amodel = "admin_users"
          break;
        case "Applicant":
          amodel = "applicants";
          break;
        default:
      }

      if(elements.length > 0) {
        for(let i=0;i<elements.length;i++) {
          if(data.status == "online") {
            elements[i].classList.add("online");
          } else {
            elements[i].classList.remove("online");
          }
        }
      }
      
      var dataTable = $("#onlineUsers").dataTable();

      if(table && data.status == "online") {
        $("#onlineUsers").dataTable().fnAddData( [
          `<a href='/admin/${amodel}/${data.user_id}'>${data.user_id}</a>`,
          model,
          data.full_name,
          data.division,
          `<i class='fas fa-circle' style='font-size: 8px;position:relative;left:0; top:-1px;'></i> ${upCaseWord(data.status)}`] );
        } else if (table && data.status == "offline") {
          let row;
          $("#onlineUsers > tbody > tr").each((i, trow) => {
            for(let i=0; i< trow.children.length;i++) {
                if(trow.children[i].innerText == model && trow.children[i+1].innerText == data.full_name) {
                  row = trow;
                }
            }
          })
          dataTable.fnDeleteRow(row)
        }
    }
  }
});


window.upCaseWord = function(word) {
  return word.toUpperCase();
}