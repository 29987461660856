$(document).on("turbolinks:load", function() {
	  $('#new_form_with_file').find("input:file").each(function(i, elem) {
	    var fileInput    = $(elem);
	    var form         = $(fileInput.parents('form:first'));
	    var submitButton = form.find('input[type="submit"]');
	    var progressBar  = $("<div class='bar'></div>");
	    var barContainer = $("<div class='progress'></div>").append(progressBar);
	    fileInput.after(barContainer);
	     fileInput.fileupload({
	      fileInput:       fileInput,
	      url:             form.data('url'),
	      type:            'POST',
	      autoUpload:       true,
	      formData:         form.data('form-data'),
	      paramName:        'file',
	      dataType:         'XML',
	      replaceFileInput: false,
	      add: function(e, data) {
	        var uploadErrors = [];
	        var acceptFileTypes = /^image\/(gif|jpe?g|png)$/i;
	        if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
	            uploadErrors.push('Not an accepted file type');
	        }
	        if(data.originalFiles[0]['size'].length && data.originalFiles[0]['size'] > 5000000) {
	            uploadErrors.push('Filesize is too big');
	        }
	        if(uploadErrors.length > 0) {
	            alert(uploadErrors.join("\n"));
	        } else {
	            data.submit();
	        }
				},
	      progressall: function (e, data) {
	        var progress = parseInt(data.loaded / data.total * 100, 10);
	        progressBar.css('width', progress + '%').text(`${progress} %`);
	      },
	      start: function (e) {
	        submitButton.prop('disabled', true);

	        progressBar.
	          css({'background':'linear-gradient(to bottom,  rgba(0,151,230,1) 0%,rgba(0,119,197,1) 100%)', 'display':'block', 'width':'0%'});
	      },
	      done: function(e, data) {
	        submitButton.prop('disabled', false);
	        progressBar.text("Uploading done");

	        // extract key and generate URL from response
	        var key   = $(data.jqXHR.responseXML).find("Key").text();

	        $("#file_hidden_key").val(key)
	        
	      },
	      fail: function(e, data) {
	        submitButton.prop('disabled', false);

	        progressBar.
	          css("background", "red").
	          text("Failed");
	      }
	    });
	  });
	});