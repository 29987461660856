// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from "jquery";
window.jQuery = $;
window.$ = $;

require('jquery-ui');
require('jquery-mask-plugin');
require('blueimp-file-upload');

require("easy-autocomplete")
require("popper.js")
require("bootstrap")
require("bootstrap-datepicker")
require("mdb")
require("cocoon-js")
require('packs/datatables');
require('packs/responsive.dataTable.min');
require('packs/validators');
require('packs/fileupload.processing');
require('packs/maskedinput');
require('packs/maskssn');

var Swal = require("sweetalert2");

import bsCustomFileInput from 'bs-custom-file-input'
import Cookies from 'js.cookie';


var checkFlag = false; 

window.linksShow = function() {
  checkFlag = !checkFlag;
  checkFlag ? $("#showMenu").removeClass("hidden") : $("#showMenu").addClass("hidden");
}

window.Cookies = Cookies;
require('packs/cookies_eu');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.sendEnrollRequest = function(e) {
  let title, confirmButtonText, cancelButtonText;
  let {url} = e.dataset;
  if(url.includes("/en/")) {
    title = "Do you want to proceed?";
    confirmButtonText = "Yes, I do";
    cancelButtonText = 'Cancel';
  } else {
    title = 'Quieres proceder?';
    confirmButtonText = 'Sí';
    cancelButtonText = 'Cancelar';
  }

  Swal.fire({
    title: title,
    icon: "question",
    iconColor: '#00AEEF',
    showCancelButton: true,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    confirmButtonColor: '#00AEEF',
    cancelButtonColor: '#ff4444'
  }).then((result) => {
    if (result.isConfirmed) {
      $.ajax({
        url: url, 
        method: "POST",
        success: function(response) {
          $("body").html(response)
        }
      })
    }
  })
}

window.submitProfile = function(e) {
  let title, confirmButtonText, cancelButtonText;
  let {id, url, locale} = e.dataset;
  if(!window.location.href.includes("/es/")) {
    title = "You won't be able to make changes to your documents anymore. Are you sure you want to proceed?";
    confirmButtonText = "Yes, I'm sure";
    cancelButtonText = 'Cancel';
  } else {
    title = 'Ya no podrá realizar cambios en sus documentos. ¿Estas seguro que deseas continuar?';
    confirmButtonText = 'Si estoy seguro';
    cancelButtonText = 'Cancelar';
  }
  Swal.fire({
    title: title,
    icon: "info",
    iconColor: '#00AEEF',
    showCancelButton: true,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    confirmButtonColor: '#00AEEF',
    cancelButtonColor: '#ff4444'
  }).then((result) => {
    if (result.isConfirmed) {
      $.ajax({
        url: url, 
        method: "POST",
        data: {applicant_id: id, locale: locale},
        success: function(response) {
          Swal.fire(response.notice, '', response.icon).then((result) => {
            window.location.pathname = response.pathname
          })
        }
      })
    } else {
      e.checked = false;
    }
  })

}

window.declineForm = function(e, url) {
  
  let title, confirmButtonText, cancelButtonText;

  if(!window.location.href.includes("/es/")) {
    title = 'Are you sure?';
    confirmButtonText = "Yes, I'm sure";
    cancelButtonText = 'Cancel';
  } else {
    title = '¿Estas seguro?';
    confirmButtonText = 'Si estoy seguro';
    cancelButtonText = 'Cancelar';
  }

  if(e.checked) {
    Swal.fire({
      title: title,
      icon: "question",
      iconColor: '#00AEEF',
      showCancelButton: true,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      confirmButtonColor: '#00AEEF',
      cancelButtonColor: '#ff4444'
    }).then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          url: url, 
          method: "POST",
          success: function(response) {
            Swal.fire(response.notice, '', response.icon).then((result) => {
              window.location.pathname = response.pathname
            })
          }
        })
      } else {
        e.checked = false;
      }
    })
  }
}

var ProgressBar = require('progressbar.js');
var metro_table = null;
var bar = null;
var line = null;

document.addEventListener("turbolinks:before-cache", function() {

  if ($("#progressCircle").length !== 0) {  
    bar.destroy()
  }  
	if ($('#metro_table_wrapper').length == 1 ) {
	  metro_table.destroy()
	}
})

function resizeCanvas(canvas) {
  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
}


function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

document.addEventListener("turbolinks:before-visit", function() {
  Turbolinks.clearCache();
})

document.addEventListener("turbolinks:load", function() {


  $("#new_form3078").submit(function(e) {
    e.preventDefault();

    var ssn = $("#form3078_ssn").unmask().val(), 
        dob = $("#form3078_dob").val();

    Swal.fire({
      title: 'Please Confirm',
      text: "Have you entered the correct Social Security Number and Date Of Birth? SSN: "+ssn+ ", DOB: " +dob+ " ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue', 
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#00AEEF',
      cancelButtonColor: '#ff4444'
    }).then((result) => {
      if (result.isConfirmed) {
       $("#new_form3078").unbind('submit').submit(); 
      }
    })

  })

  $(".collapse.show").each(function(){
    $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
  });
  
  $(".collapse").on('show.bs.collapse', function(){
    $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
  }).on('hide.bs.collapse', function(){
    $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
  });

  if($('input.zipcode_interactive')) {
    $('input.zipcode_interactive').blur(function(data) {
      var city = $('#applicant_city').length > 0 ? $('#applicant_city') : $('#employee_city');
      var state = $('#applicant_state').length > 0 ? $('#applicant_state') : $('#employee_state');

      if(this.value != "_____") {
        var elem_id = $(this).attr("id");
        $.get("/welcome/get_zip_data/" + this.value, {},
        function(data) {
          var zipcode = data;
          if (zipcode.err) {
            alert(zipcode.err);
          } else {
            city.val(zipcode.city);
            state.val(zipcode.state)
          }
        })
      } else {
        city.val("");
        state.val("");
      }
    });
  }


  $(".data-table").DataTable()

  metro_table = $('#metro_table').DataTable({
    responsive: {
        details: {
            renderer: function ( api, rowIdx, columns ) {
                var data = $.map( columns, function ( col, i ) {
                    return col.hidden ?
                        '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+
                            '<td>'+col.title+':'+'</td> '+
                            '<td>'+col.data+'</td>'+
                        '</tr>' :
                        '';
                } ).join('');

                return data ?
                    $('<table/>').append( data ) :
                    false;
            }
        }
    }
  })

$(".dash-li h5 a").click(function(e) {
  e.stopPropagation();
})

if ($("#progressCircle").length !== 0) {
    var percentage = readCookie("percentage")/100;

    bar = new ProgressBar.Circle("#progressCircle", {
      color: '#212529',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 4,
      trailWidth: 1,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        autoStyleContainer: false
      },
      from: { color: '#4cc0ef', width: 2 },
      to: { color: '#00A5E9', width: 4 },
      // Set default step function for all animate calls
      step: function(state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);

        var value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('0%');
        } else {
          circle.setText(value+'%');
        }

      }
    });
    bar.text.style.fontFamily = 'Helvetica, sans-serif';
    bar.text.style.fontSize = '1.8rem';

    bar.animate(percentage)
  }

  if ($("#progressLine").length !== 0) {
    var percentage = readCookie("percentage")/100;
    line = new ProgressBar.Line("#progressLine", {
      strokeWidth: 4,
      easing: 'easeInOut',
      duration: 1400,
      color: '#00A5E9',
      trailColor: 'transparent',
      trailWidth: 1,
      svgStyle: {width: '100%', height: '100%'},
      text: {
        style: {
          fontFamily: 'Helvetica, sans-serif',
          fontSize: '1rem',
          fontWeight: 'bold',
          color: '#FFFFFF',
          position: 'absolute',
          left: '10px',
          top: '1px',
          padding: 0,
          margin: 0,
          transform: null
        },
        autoStyleContainer: false
      },
      step: (state, line) => {
        line.setText(Math.round(line.value() * 100) + ' %');
      }
    });

    line.animate(percentage)
  }

  let placement = $(window).width() < 990 ? "top" : "right";

  placement = window.location.pathname == "/employees/dashboard" ? "top" : placement;

  $('[data-toggle="tooltip"]').tooltip({placement})

  $('#applicant_password').on('shown.bs.tooltip', function () {
    var pswd = $(this).val(); 
    if( pswd !== "") {
      highLightTooltip(pswd)
    }
  })

  bsCustomFileInput.init()
  // $('#applicant_ssn').mask('000-00-0000', {placeholder: "___-__-____"});
   $('#applicant_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });

    $(".maskunmask").on("change", function () {

        if ($(this).prop('checked')) {
            $('#applicant_ssn').unmask().mask('999-99-9999');
        }
        else {
            $('#applicant_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
        }
    });

  // ZIP CODE 
  $('#street_name_sign_work_order').unmask().mask('9999');
  $('#emergency_contact_info_med_zip').unmask().mask('99999');
  $('#emergency_contact_info_zip_one').unmask().mask('99999');
  $('#emergency_contact_info_zip_two').unmask().mask('99999');
  $('#direct_deposit_slip_postal_code').unmask().mask('99999');
  $('#employment_application_postal_code').unmask().mask('99999');
  $('#applicant_postal_code').unmask().mask('99999');
  $('#i9_form_zip_code').unmask().mask('99999');
  $('#i9_form_t_zip').unmask().mask('99999');
  $('#form2104_zip_code').unmask().mask('99999');
  $('#wotc_survey_zip_code').unmask().mask('99999');
  $('#form3078_q_13').unmask().mask('999');
  $.mask.definitions['A'] = "[A-z]";

  $(document).on("focus", ".marking_wo", function() { 
    $(this).unmask().mask('AA-99999');
  });

  $(document).on("focus", ".sign_batch_street_name_signs_work_order input", function() { 
    $(this).unmask().mask('9999');
  });

  // SOCIAL SECURITY NUMBER
  $('#employment_application_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#i9_form_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#form_w4_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#form3078_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#direct_deposit_slip_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#form8850_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#form2104_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#wotc_survey_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#badge_application_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#hb_waiver_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#background_check_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  $('#fingerprint_form_ssn').unmask().maskSSN('999-99-9999', {maskedChar: 'X', maskedCharsLength: 5 });
  
  // PHONE
  $('#badge_application_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#form8850_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#i9_form_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#applicant_home_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#applicant_cell_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#emergency_contact_info_phone_two').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#emergency_contact_info_med_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#emergency_contact_info_home_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#emergency_contact_info_phone_one').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#emergency_contact_info_alt_phone_one').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#emergency_contact_info_alt_phone_two').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_cell_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#form3078_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#form3078_bu_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#wotc_survey_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_employment_experiences_attributes_0_telephone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_employment_experiences_attributes_1_telephone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_employment_experiences_attributes_2_telephone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_employment_experiences_attributes_3_telephone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_employment_experiences_attributes_4_telephone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#employment_application_employment_experiences_attributes_5_telephone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});
  $('#fingerprint_form_phone').unmask().mask('(999) 999-9999', {autoclear: true, placeholder: ''});


  var $input = $("#crew_chief_login").add("#covid19_form_name")
  var $input2 = $("#delivery_vendor_name")

  var options = {
    getValue: "name",
    url: function(phrase) {
      return "/search-metro-employee.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "employees"
      }
    ],
    list: {
      onChooseEvent: function() {
       
      }
    }
  }

  var options2 = {
    getValue: "name",
    url: function(name) {
      return "/search-vendor-name.json?q=" + name;
    },
    categories: [
      {
        listLocation: "vendors"
      }
    ],
    list: {
      onChooseEvent: function() {
       
      }
    }
  }


  $input.easyAutocomplete(options)
  $input2.easyAutocomplete(options2)

   if(document.getElementById('signature-pad-1')) {
      var wrapper1 = document.getElementById("signature-pad-1"),
        canvas1 = wrapper1.querySelector("canvas"),
        signaturePad1;
   }

  if (canvas1){
      canvas1.height = canvas1.offsetHeight;
      canvas1.width = canvas1.offsetWidth;
      window.onresize = resizeCanvas(canvas1);
      resizeCanvas(canvas1);
      signaturePad1 = new SignaturePad(canvas1);
    }

  function clear1() {
      signaturePad1.clear();
  }

  function save(event) {
      if (signaturePad1.isEmpty()) {
          // alert("Error: Please sign both pads!");
          $("#signature-pad-1").removeClass("border-dark").addClass("border-danger");
          $("#sign-error").show();
          event.preventDefault();
      } else {
          $('.signature_pad_input1').val(signaturePad1.toDataURL());;
      }
  }

  $("#clear1").click(clear1);
  $(".signature_pad_save").click(save);

});


addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

import "controllers"
