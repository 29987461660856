
function isNumberKey(evt) {
  var charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode != 46 && charCode != 45 && charCode > 31
    && (charCode < 48 || charCode > 57))
     return false;
  return true;
}

function resizeModalCanvas(canvas) {
  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
}

$(document).on('turbolinks:load', function() {


  $('#modalSignForm').on('shown.bs.modal', function (e) {

    var wrapperOne = document.getElementById("signature-pad"),
        canvasOne = wrapperOne.querySelector("canvas"),
        signaturePadOne;
     
        canvasOne.height = canvasOne.offsetHeight;
        canvasOne.width = canvasOne.offsetWidth;
        window.onresize = resizeModalCanvas(canvasOne);
        signaturePadOne = new SignaturePad(canvasOne);
        if (window.location.pathname.includes("airport-identification-badge-applications")) {
          signaturePadOne.penColor = "rgb(31, 58, 147)";
        }
      
    resizeModalCanvas(canvasOne);

    setTimeout(function() {
      $("#modalSignForm .modal-body").css('opacity', 1)
    }, 50)

    function clearOne() {
        signaturePadOne.clear();
    }

    function saveSignature(event) {
        if (signaturePadOne.isEmpty()) {
            $("#signature-pad").addClass("border-danger");
            $("#sign-error").show();
        } else {
            $("#signature-pad").removeClass("border-danger");
            $("#sign-error").hide();
            $('.signature_pad_input').val(signaturePadOne.toDataURL());
            clearOne();
            $('#modalSignForm').modal("hide");
            if (!$("#submitBtn").hasClass("skip_submission")) {
             $("#submitBtn").closest('form').submit() 
            }
            $("#submitBtn").removeClass("hide");
            $("a[data-target='#modalSignForm']").addClass('hide');
            $("#modalSignForm .modal-body").css('opacity', 0)
        }
    }

    $("#clearOne").click(clearOne);
    $("#signaturePadSave").click(saveSignature);

  })

});